import axios from "axios";
import { getCurrencyRate } from "../../utils/getCurrency";
import { toast } from "react-toastify";

const { createSlice } = require("@reduxjs/toolkit");

const currencySlice = createSlice({
  name: "currency",
  initialState: {
    currencySymbol: "$",
    currencyRate: 1,
    currencyName: "USD",
  },
  reducers: {
    setCurrency(state, action) {
      const currencyName = action.payload;
      let currencyRate = getCurrencyRate(currencyName);

      if (currencyName === "USD") {
        return (state = {
          currencySymbol: "$",
          currencyRate: 1,
          currencyName,
        });
      }
      if (currencyName === "SAR") {
        return (state = {
          currencySymbol: "SAR",
          currencyRate,
          currencyName,
        });
      }
    },
  },
});

export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;
