import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import "animate.css";
import "swiper/swiper-bundle.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import { ToastContainer } from "react-toastify";
import "react-dropzone-uploader/dist/styles.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    {/* <PersistProvider> */}
    <App />
    <ToastContainer />
    {/* </PersistProvider> */}
  </Provider>
);
