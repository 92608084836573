import ScrollToTop from "./helpers/scroll-top";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/Server";
import "./index.css";
import "react-phone-input-2/lib/style.css";
import { AuthProvider } from "./contexts/JWTContext";
import Router from "./routes";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-chat-elements/dist/main.css";
import { useEffect } from "react";
import { getCurrencyRate } from "./utils/getCurrency";

const App = () => {
  useEffect(() => {
    getCurrencyRate("SAR");
  }, []);

  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          {/* <ScrollToTop> */}
          <Router />
          {/* </ScrollToTop> */}
        </BrowserRouter>
      </ApolloProvider>
    </AuthProvider>
  );
};

export default App;
