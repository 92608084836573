import React from 'react'

const LoadingScreen = () => {
  return (
    <div className="airomnishop-preloader-wrapper">
    <div className="airomnishop-preloader">
      <span></span>
      <span></span>
    </div>
  </div>
  )
}

export default LoadingScreen