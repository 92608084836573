import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../graphql/Server";
import { GET_PRODUCTS_PF } from "../../graphql/Shop";

export const PF_PRODUCT_THUNK = "fetch/printful/products";

// Async thunk to fetch products
export const getProducts = createAsyncThunk(PF_PRODUCT_THUNK, async (_, __) => {
  const state = __.getState()?.filters;
  try {
    const { data: { products } = { products: [] } } = await client.query({
      query: GET_PRODUCTS_PF,
      variables: {
        limit: state?.limit || 20,
        skip: state?.skip || 0,
        search: state.searchQuery ? state.searchQuery : null,
        categoryId: state.categoryId ? state.categoryId : null,
        color: !!state.colors?.length ? state.colors : null,
        size: !!state.sizes?.length ? state.sizes : null,
        storeId: state.storeId ? state.storeId : null,
      },
    });

    const transformedProducts = products?.map((product) => ({
      store: product.Store,
      category: [product.Category.name],
      discount: 0,
      id: product.id,
      price: product?.price,
      name: product.name,
      new: false,
      offerEnd: "",
      rating: product.avgRating,
      saleCount: 0,
      image: product.thumbnail,
      variation: product.Variants.map((variant) => ({
        ...variant,
        color: variant?.color,
        image:
          variant?.thumbnail_url || variant?.preview_url || product?.thumbnail,
        preview_url: variant?.preview_url || product?.thumbnail,
        thumbnail_url: variant?.thumbnail_url || product?.thumbnail,
        size: variant?.size,
      })),
    }));

    // Return the products data to the thunk's fulfilled case
    return transformedProducts;
  } catch (error) {
    console.error("Error fetching products:", error);
    // Reject with error message for thunk's rejected case
    return __.rejectWithValue("Failed to fetch products");
  }
});

// Redux slice to manage filters and products state
export const filtersSlice = createSlice({
  name: "filtersSlice",
  initialState: {
    limit: 20,
    skip: 0,
    storeId: null,
    isLoading: false,
    categoryId: null,
    products: [],
    colors: [],
    sizes: [],
    searchQuery: null,
    error: null,
  },
  reducers: {
    toggleColor(state, action) {
      const exists = state.colors.includes(action.payload);
      state.colors = exists
        ? state.colors.filter((color) => color !== action.payload)
        : [...state.colors, action.payload];
    },

    toggleSize(state, action) {
      const exists = state.sizes.includes(action.payload);
      state.sizes = exists
        ? state.sizes.filter((size) => size !== action.payload)
        : [...state.sizes, action.payload];
    },

    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },

    setSortByProducts(state, action) {
      switch (action.payload) {
        case "priceHighToLow":
          state.products = [...state.products].sort((p1, p2) => {
            const priceP1 = p1?.price || 0;
            const priceP2 = p2?.price || 0;
            return priceP2 - priceP1;
          });
          break;
        case "priceLowToHigh":
          state.products = [...state.products].sort((p1, p2) => {
            const priceP1 = p1?.price || 0;
            const priceP2 = p2?.price || 0;
            return priceP1 - priceP2;
          });
          break;
        case "default":
        default:
          break;
      }
    },
    setPage(state, action) {
      state.skip = action.payload * state.limit;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = action.payload;
        console.log("fromStore", state.products);
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload; // Store error message in state
      });
  },
});

export const {
  toggleColor,
  toggleSize,
  setSearchQuery,
  setSortByProducts,
  setPage,
} = filtersSlice.actions;
export default filtersSlice.reducer;
