import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import React, { Fragment } from "react";
import HeaderTwo from "../wrappers/header/HeaderTwo";
import FooterOne from "../wrappers/footer/FooterOne";
import ScrollToTop from "../components/scroll-to-top";

const AuthLayout = ({ footerBgClass }) => {
  return (
    <Fragment>
      <HeaderTwo />
      <Outlet />
      <FooterOne
        backgroundColorClass={footerBgClass ? footerBgClass : "bg-gray"}
        spaceTopClass="pt-100"
        spaceBottomClass="pb-70"
      />
      <ScrollToTop />
    </Fragment>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
  footerBgClass: PropTypes.string,
};

export default AuthLayout;
